#admin{          
    .sidebar{
        width: 380px;
        height: 100vh;
        background-color: #1F1E1E;
        overflow: auto; 
        @media (max-width: 767px) {
            width: 100%;
            height: auto;
            #nav.show{
                display: flex !important;
            }
        }
        .menu-painel{
            overflow: hidden;
            padding: 0;
            margin: 0;
            list-style: none;
            text-transform: uppercase;
            font-size: 16px;
            .menu-painel-item a{
                position: relative;
                display: block;
                padding: 15px 20px;
                text-decoration: none;
                font-weight: 700;
                color: $white;
                transition: color ease-in-out 300ms;                
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $white;  
                    transform: translateX(-50%);
                    transition: background-color ease-in-out 300ms;                   
                }
                &:hover{
                    color: var(--bs-primary);
                    &::after{
                        background-color: var(--bs-primary);     
                    }
                }
            }
        }
    }
    .content{
        width: 100%;
        height: 100vh;
        overflow: auto;
        @media (max-width: 767px) {
            width: 100%;
            height: auto;
        } 
    }
}
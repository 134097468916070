$primary:       #FF9000;
$secondary:     #0F0F0F;
$black:          #000;
$white:          #FFF;

body{
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    background-color: $black;
    color: $white;
}

.form-floating-label{
    min-width: 120px;
    position: relative;
    margin-top: .5rem;
    label{        
        position: absolute;
        top: -.5rem;
        left: .75rem;
        line-height: 1;
        font-size: 1rem;
        background: $secondary;
        padding: 0 10px;
    }
    &:focus-within label{        
        color: $primary;
    }
}
.form-select{
    option{
        color: $secondary;
        &:checked{
            background: $primary !important;
            color: $white !important;
        }
    }    
}

.label-image:has(.is-invalid) .btn{
    color: red !important;
    border-color: red !important;
}

.form-control:disabled, .form-control:read-only{
    color: #B9B9B9;
    background-color: #2c2c2c;
    border-color: #888;
}

.form-check{
    .form-check-input:checked + label{
        color: $primary
    }
}

.box-select{
    max-height: 300px;
    overflow-y: auto;
}

@import 'pages/login';
@import 'pages/painel';
@import 'pages/admin';
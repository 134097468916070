#painel{          
    .sidebar{
        width: 380px;
        height: 100vh;
        background-color: #1F1E1E;
        overflow: auto; 
        @media (max-width: 767px) {
            width: 100%;
            height: auto;
            #nav.show{
                display: flex !important;
            }
        }
        .menu-painel{
            overflow: hidden;
            padding: 0;
            margin: 0;
            list-style: none;
            text-transform: uppercase;
            .menu-painel-item a{
                position: relative;
                display: inline-block;
                padding: 15px 20px;
                text-decoration: none;
                font-weight: 700;
                color: $white;
                transition: color ease-in-out 300ms;                
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    width: 150px;
                    height: 2px;
                    background-color: $white;  
                    transform: translateX(-50%);
                    transition: background-color ease-in-out 300ms;                   
                }
                &:hover{
                    color: var(--bs-primary);
                    &::after{
                        background-color: var(--bs-primary);     
                    }
                }
            }
        }
    }
    .content{
        width: 100%;
        height: 100vh;
        overflow: auto;
        @media (max-width: 767px) {
            width: 100%;
            height: auto;
        }    
        #progress-steps{
            width: 100%;
            overflow-x: auto;
            #progress-steps-bar{
                display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;  
                position: relative;              
                &::before{
                    content: '';
                    display: block;
                    width: calc(100% - 22px);
                    height: 15px;
                    background: #454444;
                    position: absolute;
                    top: 50%;
                    left: 11px;
                    transform: translateY(-50%);
                    z-index: 1;
                }
                #progress-width{
                    display: block;
                    width: 0%;
                    height: 15px;
                    background: $primary;
                    position: absolute;
                    top: 50%;
                    left: 11px;
                    transform: translateY(-50%);
                    z-index: 2;
                }
                .icon-step-wrap{
                    min-width: 130px;
                    max-width: 130px;
                    margin: 10px;  
                    .icon-step-item{                        
                        background-color: #454444;
                        color: $primary;
                        z-index: 3;
                        &.active{
                            box-shadow: 0 0 10px 0 $primary;
                        }
                        .icon{   
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            display: block;
                            width: 60px;
                            height: 60px;      
                            line-height: 1;           
                            font-size: 60px;
                            transform: translate(-50%, -50%);                            
                        }
                    }                        
                }  
            }
        }
        .produto-item{
            cursor: pointer;
            box-shadow: 0 0 10px 3px transparent;
            &:has(input[type=radio]:checked), &:has(input[type=checkbox]:checked){
                .produto-item-img{
                   box-shadow: 0 0 10px 3px $primary;
                }
            }
            .produto-serie{
                opacity: 0;
                transform: translateY(-100%);
                transition: opacity ease-in-out 300ms, transform ease-in-out 300ms;
                &.required{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}
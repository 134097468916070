#login{
    width: 100%;
    height: 100vh;
    .login-wrap{
        width: 500px;
        max-width: 100%;
        transition: all ease-in-out 300ms;
    }
    .bg-digital{
        background: rgba($secondary, 0.25) url('/img/bg-digital.jpg') center no-repeat;
        background-size: cover;
        background-blend-mode: overlay;
        color: $white;
    }

    .switch-form{
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 320px;
        color: $white;
        border: 1px solid $white;
        border-radius: 99px;
        position: relative;

        &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background: $primary;
            border-radius: 99px;
            z-index: 1;
            transition: left ease-in-out 300ms;
        }      

        &:has(#form-type-login:checked){            
            &::before{
                left: 0;
            }            
        }  
        &:has(#form-type-register:checked){
            &::before{
                left: 50%;
            }            
        }

        .switch-login:has(#form-type-login:checked){
            color: $secondary;
        }
        .switch-register:has(#form-type-register:checked){
            color: $secondary;
        }

        .switch-form-button{
            position: relative;
            display: block;
            width: 50%;
            cursor: pointer;
            text-align: center;
            border-radius: 99px;
            padding: 15px;
            z-index: 2;
        } 

    }
    :has(#form-type-login:checked) #form-login{
        display: block;
    }
    :has(#form-type-register:checked) #form-register{
        display: block;
    }
}